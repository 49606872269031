<template>
  <div>
    <div class="content">
      <div class="box">
        <div class="item" style="background:#409EFF">
          <div>
            <p class="basic-title">{{ $t('finance.Report.576422-0') }}</p>
            <p>{{ countId ? countId : 0 }}{{ $t('finance.Report.576422-1') }}</p>
          </div>
        </div>
        <div class="item" style="background:#67C23A">
          <div>
            <p class="basic-title">{{ $t('finance.Report.576422-2') }}</p>
            <p>$HK{{ sumAmount ? sumAmount : "0.00" }}{{ $t('finance.Report.576422-3') }}</p>
          </div>
        </div>
        <div class="item" style="background:#E6A23C">
          <div>
            <p class="basic-title">{{ $t('finance.Report.576422-4') }}</p>
            <p>$HK{{ shouldPrice ? shouldPrice : "0.00" }}{{ $t('finance.Report.576422-3') }}</p>
          </div>
        </div>
        <div class="item" style="background:#C0C4CC">
          <div>
            <p class="basic-title">{{ $t('finance.Report.576422-5') }}</p>
            <p>$HK{{ paFzMoney ? paFzMoney : "0.00" }}{{ $t('finance.Report.576422-3') }}</p>
          </div>
        </div>
      </div>
      <div>
        <div class="title"><span>{{ $t('finance.Report.576422-6') }}</span></div>
        <div class="num-box">
          <div class="item">
            <p class="num-title">{{ $t('finance.Report.576422-7') }}</p>
            <p>{{ dayCountId ? dayCountId : 0 }}{{ $t('finance.Report.576422-1') }}</p>
          </div>
          <div class="item">
            <p class="num-title">{{ $t('finance.Report.576422-8') }}</p>
            <p>$HK{{ daySumAmount ? daySumAmount : "0.00" }}{{ $t('finance.Report.576422-3') }}</p>
          </div>
        </div>
        <div class="num-box">
          <div class="item">
            <p class="num-title">{{ $t('finance.Report.576422-9') }}</p>
            <p>{{ monthCountId ? monthCountId : 0 }}{{ $t('finance.Report.576422-1') }}</p>
          </div>
          <div class="item">
            <p class="num-title">{{ $t('finance.Report.576422-10') }}</p>
            <p>$HK{{ monthSumAmount ? monthSumAmount : "0.00" }}{{ $t('finance.Report.576422-3') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countId: 0, //有效订单总数
      sumAmount: 0, //有效订单总额
      shouldPrice: 0, //商家应结算总额
      paFzMoney: 0, //商家已结算总额
      dayCountId: 0, //日订单总数
      daySumAmount: 0, //日订单总额
      monthCountId: 0, //月订单总额
      monthSumAmount: 0, //月订单总额
    };
  },
  created() {
    this.getData();
    // console.log(this.$store.state.user.shopId);
  },
  methods: {
    getData() {
      const shopId = this.$store.state.user.shopId;
      // 应结算总额，已结算总额
      this.$api.order
        .settlementOrder({
          shopId: shopId,
        })
        .then((res) => {
          const data = res.data[0];
          this.shouldPrice = data.shouldPrice;
          this.paFzMoney = data.paFzMoney;
        });
      // 订单数据统计 日
      this.$api.order
        .statisticsOrder({
          shopId: shopId,
          day: 1,
        })
        .then((res) => {
          const data = res.data[0];
          this.dayCountId = data.dayCountId;
          this.daySumAmount = data.daySumAmount;
          // console.log(res);
        });
      // 订单数据统计 月
      this.$api.order
        .statisticsOrder({
          shopId: shopId,
          day: 30,
        })
        .then((res) => {
          const data = res.data[0];
          this.monthCountId = data.dayCountId;
          this.monthSumAmount = data.monthSumAmount;
        });
      // 统计商家有效订单及订单数
      this.$api.order
        .validOrder({
          shopId: shopId,
        })
        .then((res) => {
          const data = res.data[0];
          this.countId = data.countId;
          this.sumAmount = data.sumAmount;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 32px 20px;
}
.content {
  .box {
    display: flex;
    // justify-content: space-between;
    justify-content: space-around;
    align-items: center;
    .item {
      width: 18%;
      height: 150px;
      color: #fff;
      font-size: 24px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .basic-title {
        font-size: 18px;
      }
    }
  }
  .title {
    font-size: 28px;
    font-weight: bold;
    margin: 24px 0;
  }
  .num-box {
    display: flex;
    .item {
      width: 200px;
      line-height: 32px;
      text-align: center;
      margin-bottom: 32px;
      font-size: 24px;
      color: #909399;
      font-weight: bold;
      .num-title {
        color: #c0c4cc;
        font-size: 14px;
      }
    }
  }
}
</style>
